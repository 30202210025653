import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Login from './auth/Login';
import Register from './auth/Register';

import Exam from './admin/AddExam';

import Grade from './admin/AddGrade';
import Addvideo from './admin/AddVideo';
import AddSubject from './admin/AddSubject';
import Quiz from './admin/AddQuiz';

import Landing from './components/Landing';
import Exampdf from './admin/AddExampdf';
import Notespdf from './admin/Addnotes'
import Adddata from './components/adminbuttons';
import Scripture from './admin/Addscripture';
import Reels from './admin/AddReel'

function App() {
  
  return(
    
    
  <BrowserRouter>
  
    <Routes>
      <Route path='/dashboard'>
        <Route path='/dashboard/$2a$12$mnIqUYPTlrS.iyDfCwEHH.PvBhbsuhsrt3xylzKtNqh6Vl0lIaw2.' element={<Exam/>} ></Route>
        <Route path='/dashboard/$2a$12$mnIqUYPTlrS.iyDfCwEHH.PvBhbsuhsrt3x$2a$12$mnIqUYPTlrS.iyDfCwEHH.PvBhbsuhsrt3xylzKtNqh6Vl0lIaw2.' element={<Grade/>}></Route>
        <Route path='/dashboard/$2a$12$mnIqUY$2a$12$mnIqUYPTlrS.iyDfCwEHH.PvBhbsuhsr$2a$12$mnIqUYPTlrS.iyDfCwEHH.PvBhbsuhsrt3xylzKtNqh6Vl0lIaw2.' element={<Quiz/>}></Route>
        <Route path='/dashboard/$2a$12$mnIqUYPTlr$2a$12$mnIqUYPTlrS.iyDfCwEHH.PvBhbsuh$2a$12$mnIqUYPTlrS.iyDfCwEHH.PvBhbsuhsrt3xylzKtNqh6Vl0lIaw2.qh6Vl0lIaw2..' element={<AddSubject/>}></Route>
        <Route path='/dashboard/$2a$$2a$12$mnIqUYPTlrS.iyDfCwEHH.PvBhbsuhsrt3$2a$12$mnIqUYPTlrS.iyDfCwEHH.PvBhbsuhsrt3xylzKtNqh6Vl0lIaw2.KtNqh6Vl0lIaw2.' element={<Addvideo/>}></Route>
        <Route path='/dashboard/$2a$$2a$12$mnIqUYPTlrS.iyDfCwEHH.PvBhbsuhsrt3$2a$12$mnIqUYPTlrS.iyDfCwEHH..KtNqh6Vl0lIaw2.' element={<Scripture/>}></Route>
        <Route path='/dashboard/$2a$$2a$12$mnIqUYPTlrS.iyDfCwEHH' element={<Exampdf/>}></Route>
        <Route path='/dashboard/$2a$$2a$12$mnIqUYPTlrS.iyDfCwEHHBhbsuhsrt3$2a$12$mnIqUYPTlrS' element={<Notespdf/>}></Route>
        <Route path='/dashboard/$2a$$2a$12$mnIqUYPTlrS.iyDfCw2a$$2a$12$mnIqUYEHHBhbsuhsrt3$2a$12$mnIqUYPTlrS' element={<Reels/>}></Route>
        </Route>
        <Route path='/wMWU3MTQxZjZhZWU0ODAxYjZlOWYiLC' element={<Adddata/>}></Route>
      
            <Route path='/' element={<Landing/>}></Route>
      <Route path='/login' element={<Login/>}></Route>
      
      
      <Route path='/Theloveofjesushaskeptmethisfar' element={<Register/>}></Route>
      
      </Routes>
   

  </BrowserRouter>



  );

}
export default App;







